<template>
  <DashboardTemplate>
    <div class="text-center height-screen-100">
      <div class="min-height-screen-120 text-center pd-5 position-relative">
        <h2 class="font-26 mg-b-0">
          Thailand Export Readiness Assessment and Knowledge Management
          <br />(TERAK)
        </h2>
        <!-- <el-button
          type="success"
          style="cursor: unset"
          class="font-44 mg-y-1 pd-4"
          circle
          ><i class="fas fa-check font-38"></i
        ></el-button> -->
        <svg
          class="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            class="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            class="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
        <p class="font-24">คุณ{{ user.name }} {{ user.surname }}</p>
        <h2 class="text-success font-26 font-weight-bold">
          ส่งข้อมูลการประเมินเรียบร้อยแล้ว!
        </h2>
        <hr class="line-space mg-y-2" />
        <p class="font-26" v-if="!checkComplacency">
          ประเมินความพึงพอใจในการใช้งานระบบประเมิน TERAK
        </p>
        <div class="text-center mg-t-1">
          <el-button
            v-if="checkComplacency"
            class="font-28 pd-x-1"
            :class="widthMax < 768 ? 'w-100' : ''"
            type="primary"
            @click="$router.push('/result'), getUserById()"
            >ดูผลการประเมิน</el-button
          >
          <el-button
            v-else
            class="font-24 pd-x-1"
            :class="widthMax < 768 ? 'w-100' : ''"
            type="primary"
            @click="$router.push('/feedback'), getUserById()"
            >ประเมินความพึงพอใจ</el-button
          >
        </div>
      </div>
    </div>
  </DashboardTemplate>
</template>
<style scoped>
.line-space {
  border: 1px solid #e5e5e5;
  width: 210px;
}
</style>
<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardTemplate
  },
  data() {
    return {
      checkComplacency: false,
      widthMax: window.innerWidth
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    }
  },
  mounted() {
    this.checkAssessment();
  },
  methods: {
    async checkAssessment ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.get(`check/conplacency`);
      if (res.data.success) {
        this.checkComplacency = res.data.obj;
      }
    },
    getUserById ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get("user").then(res => {
        if (res.data.success) {
          this.$store.commit("SET_USER", res.data.obj);
        }
      });
    }
  }
};
</script>
